var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import "./style.css";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Mesh, MeshNormalMaterial, PerspectiveCamera, Scene, TextureLoader, TorusGeometry, WebGLRenderer, } from "three";
import { loadFont } from "./loadFont";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
/**
 * Base
 */
var canvas = document.querySelector("canvas.webgl");
var scene = new Scene();
var textureLoader = new TextureLoader();
var size = {
    width: window.innerWidth,
    height: window.innerHeight,
};
var start = function () { return __awaiter(void 0, void 0, void 0, function () {
    var helvetikerFont, matcapTexture, textGeometry, matcapMaterial, text, donutGeometry, _, donut, donutScale;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, loadFont("/fonts/helvetiker_regular.typeface.json")];
            case 1:
                helvetikerFont = _a.sent();
                matcapTexture = textureLoader.load("/textures/matcaps/1.png");
                textGeometry = new TextGeometry("Orange Cat!", {
                    font: helvetikerFont,
                    size: 0.5,
                    height: 0.2,
                    curveSegments: 30,
                    bevelEnabled: true,
                    bevelThickness: 0.03,
                    bevelSize: 0.02,
                    bevelOffset: 0,
                    bevelSegments: 4,
                });
                textGeometry.computeBoundingBox();
                textGeometry.center();
                matcapMaterial = new MeshNormalMaterial();
                text = new Mesh(textGeometry, matcapMaterial);
                scene.add(text);
                donutGeometry = new TorusGeometry(0.3, 0.2, 20, 45);
                for (_ = 0; _ < 100; _++) {
                    donut = new Mesh(donutGeometry, matcapMaterial);
                    donut.position.x = (Math.random() - 0.5) * 10;
                    donut.position.y = (Math.random() - 0.5) * 10;
                    donut.position.z = (Math.random() - 0.5) * 10;
                    donut.rotation.x = Math.random() * Math.PI;
                    donut.rotation.y = Math.random() * Math.PI;
                    donutScale = Math.random();
                    donut.scale.set(donutScale, donutScale, donutScale);
                    scene.add(donut);
                }
                return [2 /*return*/];
        }
    });
}); };
window.addEventListener("resize", function () {
    size.width = window.innerWidth;
    size.height = window.innerHeight;
    camera.aspect = size.width / size.height;
    camera.updateProjectionMatrix();
    renderer.setSize(size.width, size.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
var camera = new PerspectiveCamera(75, size.width / size.height, 0.1, 100);
camera.position.x = 1;
camera.position.y = 1;
camera.position.z = 2;
scene.add(camera);
var controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
var renderer = new WebGLRenderer({
    canvas: canvas,
});
renderer.setSize(size.width, size.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
var tick = function () {
    controls.update();
    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
};
start();
tick();
